import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const CSGuidesShopPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/counter-side/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Shops and currencies</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/counterside/categories/guide_shops.png"
            alt="Shops and currencies"
          />
        </div>
        <div className="page-details">
          <h1>Shops and currencies</h1>
          <h2>
            This guide explains what's worth buying daily/weekly from all the
            shops.
          </h2>
          <p>
            Last updated: <strong>22/04/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297253"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          The purpose of this guide is to briefly go over CounterSide’s various
          shops and currencies and detail spending priorities for each one.
        </p>
        <p>The following shops are covered in this guide:</p>
        <ul>
          <li>Today’s Deals</li>
          <li>Convenience Goods Shop</li>
          <li>Planet Mall</li>
          <li>Dive Shop</li>
          <li>Shadow Palace Shop</li>
          <li>Consortium Shop</li>
          <li>Business Card Shop</li>
          <li>Gauntlet Shop</li>
          <li>Danger Close Shop</li>
          <li>Substream Shops</li>
        </ul>
        <SectionHeader title="Today’s Deals" />
        <p>
          Today’s Deals is CounterSide’s daily shop. The shop contains a variety
          of random items that can be purchasable for credits or quartz, and the
          shop offerings can be refreshed for 15 quartz up to 5 times a day.
          Importantly, the daily shop has a chance of listing Employee Contracts
          for credits, allowing you to purchase them at a heavily discounted
          price. You are encouraged to refresh the shop 5 times every day.
        </p>
        <p>
          The daily shop can be accessed from:{' '}
          <strong>
            Shop &gt; Shop List &gt; Exchange Center &gt; Today’s Deals
          </strong>
        </p>
        <h5>What to Buy</h5>
        <ol>
          <li>
            It’s easy to farm up millions of credits so you might as well
            purchase all the items listed for credits. However, most of the
            items are worth so little (what are you going to do with a single
            APT core?) you might as well ignore them. Items of note are:
          </li>
          <ul>
            <li>Prioritize: Employee Contracts, Ship Components</li>
            <li>Consider: Special Catalyst, Conversion Approval</li>
          </ul>
          <li>
            The items listed for quartz are grossly overpriced. However, you may
            opt to spend quartz on Classified Employee Contracts.
          </li>
        </ol>
        <StaticImage
          src="../../../images/counterside/generic/shop_1.webp"
          alt="Shops and currencies"
        />
        <SectionHeader title="Convenience Goods Shop" />
        <p>
          The Convenience Goods shop sells an assortment of items. The majority
          of it is a scam, but some items are genuinely worth getting.
        </p>
        <p>
          The Convenience Goods shop can be accessed from:{' '}
          <strong>
            Shop &gt; Shop List &gt; Exchange Center &gt; Convenience Goods
          </strong>
        </p>
        <h5>What to Buy</h5>
        <ol>
          <li>1/3 Anti-Air Training Permit (Daily)</li>
          <ul>
            <li>
              Anti-Air Training Simulation drops S-APT cores. S-APT cores are
              one of your major resource bottlenecks because you need them to
              limit break characters and craft fusion cores.
            </li>
            <li>
              While the Anti-Air Simulation provides the most value in the long
              run, there is nothing wrong with doing the other simulations if
              you need those materials.
            </li>
          </ul>
          <li>3/3 Quantum Coords Crystals (Daily)</li>
          <ul>
            <li>
              Players have the option to purchase 3 Quantum Coords Crystals
              daily. Quantum Coords Crystals are used to gain entry into Shadow
              Palace.
            </li>
            <li>
              Of note, the Shadow Palace currency can be exchanged for T4
              enhancement modules. At Shadow Palace 5, you can save ~3600
              Eternium that would otherwise have been spent farming an
              equivalent amount of gear EXP from Anastasia’s Supply Maintenance.
            </li>
          </ul>
          <li>1/1 Set Binary (Monthly)</li>
          <ul>
            <li>
              The monthly set binary package gives you 30 set binaries for 1000
              quartz. Set binaries are a scarce resource needed to reroll set
              bonuses on gear, and since gear is everything in this game you’re
              going to need as many set binaries as you can get.
            </li>
          </ul>
        </ol>
        <StaticImage
          src="../../../images/counterside/generic/shop_2.webp"
          alt="Shops and currencies"
        />
        <ul>
          <li>Red outline - Priority</li>
          <li>Blue outline - Up to player discretion</li>
        </ul>
        <SectionHeader title="Planet Mall" />
        <p>
          The Planet Mall sells SSR Operators. Operators are mainly used in the
          competitive game modes (i.e. Ranked Gauntlet, Danger Close). The
          system is a massive resource sink and is something players will want
          to grind through once they are satisfied with their character roster.
        </p>
        <p>
          Task Planet Points are earned through recruitment at a rate of 10 Task
          Planet Points per pull. Task Planet Points are used almost exclusively
          to purchase operators.
        </p>
        <p>
          The Convenience Goods shop can be accessed from:{' '}
          <strong>
            Shop &gt; Shop List &gt; Exchange Center &gt; Planet Mall &gt;
            Operator
          </strong>
        </p>
        <h5>What to Buy</h5>
        <ul>
          <li>Your first copy of Kim Hana</li>
          <ul>
            <li>
              After you get Kim Hana, forget about this shop. Sit on your Task
              Planet Points and let it accumulate until you get a better idea of
              which competitive game mode you want to prioritize first.
            </li>
          </ul>
        </ul>
        <StaticImage
          src="../../../images/counterside/generic/shop_3.webp"
          alt="Shops and currencies"
        />
        <p>
          For more information on operators, we heavily encourage you to check
          the Prydwen Operator Guide. This guide goes over the operator system
          in far more detail and touches on the operator meta so you’ll know
          what to expect and see also, Prydwen’s operator reviews:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Operators and you"
            link="/counter-side/guides/operators-and-you"
            image={
              <StaticImage
                src="../../../images/counterside/categories/guide_operators.png"
                alt="Operators and you"
              />
            }
          />
          <CategoryCard
            title="Operators"
            link="/counter-side/operators"
            image={
              <StaticImage
                src="../../../images/counterside/categories/guide_operators.png"
                alt="Operators"
              />
            }
          />
        </Row>
        <SectionHeader title="Dive Shop" />
        <p>
          Completing Dives will yield Imaginary Cores, and at higher
          difficulties (Dive 50 onwards), also drop Gold Bullets. These two
          currencies are used in their respective shops.
        </p>
        <p>
          The Dive shops can be accessed from:{' '}
          <strong>Shop &gt; Shop List &gt; Exchange Center &gt; Dive</strong>
        </p>
        <h5>What to Buy - Imaginary Core</h5>
        <ul>
          <li>Special Ship Schematics & High Rarity Ship Components</li>
          <ul>
            <li>
              You will need ship crafting materials to craft ships. There are
              very few ships that actually affect gameplay in a meaningful way.
              You do not need to feel pressured to craft all the ships; you can
              complete almost all the general content with a level 100
              Enterprise.
            </li>
            <li>
              Rerolling ship sockets becomes the endgame resource sink for High
              Rarity Ship Components, though the performance increase is so
              minor and the RNG is atrocious so it’s a fairly low priority
              system.
            </li>
          </ul>
          <li>Tuning Binaries</li>
          <ul>
            <li>
              Once you run out of ships to craft, the Imaginary Core shop
              becomes your secondary source of tuning binaries. There is no
              limit on the number of tuning binaries you can buy.
            </li>
          </ul>
        </ul>
        <StaticImage
          src="../../../images/counterside/generic/shop_4.webp"
          alt="Shops and currencies"
        />
        <ul>
          <li>Red outline - Priority</li>
          <li>Blue outline - Up to player discretion</li>
        </ul>
        <h5>What to Buy - Gold Bullet</h5>
        <ul>
          <li>Counter Maze gear</li>
          <ul>
            <li>
              Given the glacial pace at which you’ll be earning Gold Bullets,
              you may want to prioritize the case first, as they are used in
              more gear presets than other Maze pieces.
            </li>
          </ul>
          <li>Other Maze gear</li>
          <li>Binaries/ Evolved-001</li>
          <ul>
            <li>
              Evolved-001 is a farmable ASSR that can be purchased for 3000 Gold
              Bullets. Evolved-001 is used exclusively as a PVP unit. Players
              may opt to skip it.
            </li>
          </ul>
        </ul>
        <StaticImage
          src="../../../images/counterside/generic/shop_5.webp"
          alt="Shops and currencies"
        />
        <ul>
          <li>Red outline - Priority</li>
          <li>Blue outline - Up to player discretion</li>
        </ul>
        <p>For more information on dives, see those guides:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Mode overview"
            link="/counter-side/guides/dive-introduction"
            image={
              <StaticImage
                src="../../../images/counterside/categories/guide_diveintro.png"
                alt="Dive introduction"
              />
            }
          />
          <CategoryCard
            title="Dive 48 - 50 guide"
            link="/counter-side/guides/dive-49-50"
            image={
              <StaticImage
                src="../../../images/counterside/categories/guide_dive.png"
                alt="Dive 48 and 50"
              />
            }
          />
          <CategoryCard
            title="Dive - 60"
            link="/counter-side/guides/dive-60"
            image={
              <StaticImage
                src="../../../images/counterside/categories/guide_dive.png"
                alt="Dive 60"
              />
            }
          />
        </Row>
        <SectionHeader title="Shadow Palace Shop" />
        <p>
          Clearing Shadow Palace awards Shadow Fragments that can be exchanged
          for gear molds and binaries. Shadow Palace gear (primarily Spectral
          gear) represents a cheap, low-commitment alternative to Maze/ Gordias
          gear. As farmable gear with a smaller set and substat pool, it is much
          easier to assemble niche sets without feeling like you’ve wasted a
          Maze piece.
        </p>
        <p>Some general notes on Shadow Palace items:</p>
        <ul>
          <li>
            1 Quantum Coords Crystal is generated daily, which can be
            accumulated up to a limit of 7.
          </li>
          <ul>
            <li>
              Quantum Coords Crystals are used to gain entry into the Shadow
              Palaces.
            </li>
            <li>
              You may consider purchasing an additional 3 Quantum Coords
              Crystals daily.
            </li>
          </ul>
          <li>Clearing the Shadow Palace will reward Shadow Fragments.</li>
          <ul>
            <li>Shadow Fragments can be used in the Shadow Palace shop.</li>
          </ul>
          <li>
            There is an instant skip function, so you don’t need to clear the
            Palaces past the first clear.
          </li>
        </ul>
        <p>
          The Shadow Palace shop can be accessed from:{' '}
          <strong>
            Shop &gt; Shop List &gt; Exchange Center &gt;Shadow Palace
          </strong>
        </p>
        <h5>What to Buy</h5>
        <ol>
          <li>50/50 Set Binaries (Monthly)</li>
          <ul>
            <li>
              The set binaries refresh monthly, while the rest of the shop
              refreshes weekly. You may want to pace yourself if you intend to
              purchase some of the weekly items. You will earn far more Shadow
              Fragments than is needed to clear the shop, so in the long-run
              this won’t be an issue.
            </li>
          </ul>
          <li>30/30 Tuning Binaries (Weekly)</li>
          <li>75% Discounted Universal Molds</li>
          <li>Enhancement Module</li>
          <ul>
            <li>
              Refer back to the Convenience Goods Shop section for more details.
            </li>
          </ul>
        </ol>
        <StaticImage
          src="../../../images/counterside/generic/shop_6.webp"
          alt="Shops and currencies"
        />
        <ul>
          <li>Red outline - Priority</li>
          <li>Blue outline - Up to player discretion</li>
        </ul>
        <p>For more information on Shadow Palace, check this guide:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Mode overview"
            link="/counter-side/guides/shadow-palace"
            image={
              <StaticImage
                src="../../../images/counterside/categories/shadow_overview.png"
                alt="Shadow Palace"
              />
            }
          />
        </Row>
        <SectionHeader title="Consortium Shop" />
        <p>
          Consortium Co-op Battle (CCB) is a game mode exclusive to Consortiums,
          CounterSide’s equivalent to player guilds. Participating in Consortium
          activities will grant Coalition Tokens, which can be exchanged for
          various items in the Consortium Shop.
        </p>
        <p>Coalition Tokens are earned via:</p>
        <ul>
          <li>Consortium Check-in rewards (Daily)</li>
          <li>Consortium donations (Daily)</li>
          <li>CCB Raid Boss damage rewards (Weekly)</li>
          <li>CCB Kill Points milestones rewards (Seasonal)</li>
        </ul>
        <p>
          The Consortium shop can be accessed from:{' '}
          <strong>
            Shop &gt; Shop List &gt; Exchange Center &gt; Consortium
          </strong>
        </p>
        <h5>What to Buy</h5>
        <ol>
          <li>5/5 Set Binaries (Weekly)</li>
          <li>Single Purchase Items*</li>
          <ul>
            <li>
              The single purchase items are permanent, while the rest of the
              shop refreshes weekly. You may want to pace yourself if you intend
              to purchase some of the weekly items.
            </li>
          </ul>
          <li>1/1 Fusion Core (Weekly)</li>
        </ol>
        <StaticImage
          src="../../../images/counterside/generic/shop_7.webp"
          alt="Shops and currencies"
        />
        <ul>
          <li>Red outline - Priority</li>
          <li>Blue outline - Up to player discretion</li>
        </ul>
        <p>For more information on Guild Coop, check this guide:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Mode overview"
            link="/counter-side/guides/guild-coop"
            image={
              <StaticImage
                src="../../../images/counterside/categories/icon_guild_raid.png"
                alt="Guild raid"
              />
            }
          />
        </Row>
        <p>*The full list of permanent items have been listed below:</p>
        <ul>
          <li>Step 1 - 6 Fusion Cores</li>
          <li>Step 2 - 20 Set Binaries</li>
          <li>Step 3 - 6 Fusion Cores</li>
          <li>Step 4 - 250 Special Catalysts</li>
          <li>Step 5 - 6 Fusion Cores</li>
          <li>Step 6 - 75 T6 Enhancement Modules</li>
          <li>Step 7 - 6 Fusion Cores</li>
          <li>Step 8 - 150 Tuning Binaries</li>
          <li>Step 9 - 6 Fusion Cores</li>
        </ul>
        <SectionHeader title="Business Card Shop" />
        <p>
          Business Cards can be obtained by dismissing units of R rarity or
          higher. It is difficult to clear this shop, but as long as you are
          farming the Substream stages, you should have a constant inflow of
          business cards. Obviously, pulling on the gacha banners will also
          generate business cards.
        </p>
        <p>
          The Business Card shop can be accessed from:{' '}
          <strong>
            Shop &gt; Shop List &gt; Exchange Center &gt; Business Card
          </strong>
        </p>
        <h5>What to Buy</h5>
        <ol>
          <li>5/5 Fusion Cores (Weekly)</li>
          <li>Prime Rearmament Data</li>
          <ul>
            <li>
              Early on you will have many strong rearms to catch up on so this
              is very important. After you finish those, this is an unnecessary
              purchase.
            </li>
          </ul>
          <li>10/10 Fusion Core Fragments (Weekly)</li>
          <li>10/10 [S-APT Core] Coupons (Weekly)</li>
          <li>Lifetime Contract</li>
          <ul>
            <li>
              At 100 Loyalty, characters will receive a 2% stat buff. Loyalty is
              lost when characters fail a mission. Lifetime Contracts prevent
              characters from losing Loyalty.
            </li>
          </ul>
        </ol>
        <StaticImage
          src="../../../images/counterside/generic/shop_8.webp"
          alt="Shops and currencies"
        />
        <ul>
          <li>Red outline - Priority</li>
          <li>Blue outline - Up to player discretion</li>
        </ul>
        <SectionHeader title="Gauntlet Shop" />
        <p>
          The Gauntlet shop is composed of a monthly refreshing tiered shop of
          various resources, a permanent shop selling limited quantities of T6
          gear (Maze, Gordias, Seasonal), and some minor cosmetic items.
        </p>
        <p>
          Gauntlet shop items are purchased with Gauntlet Points. Contrary to
          the game mode, you do not actually need to play PVP against real
          players to receive the full benefit of the Gauntlet shop. Unearned
          Gauntlet Points will naturally accumulate up to a limit of 900. These
          unearned Gauntlet Points can be received by playing:
        </p>
        <ul>
          <li>Ranked Gauntlet: Real-time PVP against other players</li>
          <li>
            Strategy Battle: Fighting pre-assembled teams built by other players
          </li>
          <li>
            Strategy Battle: Fighting pre-assembled bot teams built into the
            game mode
          </li>
        </ul>
        <p>
          For the PVP-adverse, you can repeatedly fight the bot teams to collect
          all your Gauntlet Points. Players will also receive Gauntlet Points at
          the end of each week (excluding the last week of the season) depending
          on their Ranked Gauntlet and Strategy Battle rank division.
        </p>
        <p>
          The Gauntlet shop can be accessed from:{' '}
          <strong>Shop &gt; Shop List &gt; Seasonal&gt; Gauntlet</strong>
        </p>
        <h5>What to Buy</h5>
        <ol>
          <li>Regular Gear</li>
          <ul>
            <li>
              Prioritize the Counter gear, then purchase the Soldier and Mech
              Maze gear as needed.
            </li>
          </ul>
          <li>Gauntlet Point Shop</li>
          <ul>
            <li>
              The Gauntlet Point shop refreshes on a monthly basis. The shop
              isn’t good enough that you should feel pressured to clear it every
              month. The items offered at the shop’s third tier are largely up
              to player discretion.
            </li>
          </ul>
          <li>Seasonal Gear</li>
          <ul>
            <li>
              Prioritize the Counter Sincere movements. Purchase Devoted cases
              as needed.
            </li>
            <li>
              Realistically, you’re not going to touch Seasonal gear until you
              finish working on Maze/ Gordias. These items are extremely low
              priority.
            </li>
            <li>
              Seasonal gear will periodically receive 50% discounts, aligning
              with their namesake PVP season. These discounts are nice to have,
              but in the long run it won’t make a difference. It is not worth
              waiting for the seasonal gear discount to purchase the seasonal
              gear.
            </li>
          </ul>
        </ol>
        <StaticImage
          src="../../../images/counterside/generic/shop_9.webp"
          alt="Shops and currencies"
        />
        <SectionHeader title="Danger Close Shop" />
        <p>
          Danger Close is CounterSide’s competitive PVE game mode. Danger Close
          sees you optimizing a team to kill one of many rotating bosses as
          efficiently as possible to maximize your score.
        </p>
        <p>
          Rewards are distributed at the end of every DC season depending on
          your leaderboard placement at the end of the season. Importantly, DC
          awards Danger Close Performance Points, a currency used to purchase
          items from the Danger Close shop.
        </p>
        <p>Danger Close Performance Points can be earned from:</p>
        <ul>
          <li>Score milestones against Danger Close bosses. </li>
          <li>
            Your leaderboards placement at the end of every Danger Close season.
          </li>
        </ul>
        <p>
          The Danger Close shop can be accessed from:{' '}
          <strong>Shop &gt; Shop List &gt; Seasonal&gt; Danger Close</strong>
        </p>
        <h5>What to Buy</h5>
        <ol>
          <li>Limited Gear (Maze)</li>{' '}
          <ul>
            <li>
              Prioritize the Counter gear, then purchase the Soldier and Mech
              Maze gear as needed.
            </li>{' '}
          </ul>
          <li>50/50 Set Binaries (Monthly)</li>{' '}
          <ul>
            <li>
              50 Set Binaries are purchasable from the Gear Growth Support tab,
              refreshing monthly.{' '}
            </li>{' '}
          </ul>
          <li>1/1 Fusion Core (Monthly)</li>{' '}
          <ul>
            <li>
              For some bizarre reason you can buy a singular Fusion Core from
              the Employee Growth Support tab for 20 Danger Close Performance
              Points, refreshing monthly.
            </li>{' '}
          </ul>
          <li>Limited Gear (Hummingbird)</li>{' '}
          <ul>
            <li>
              Prioritize the Counter gear. Unless you know exactly what you
              intend to do with the Soldier/ Mech Hummingbird weapon, do not
              bother purchasing them.
            </li>
            <li>
              Hummingbird is used to make CDR sets for backline supporters.
            </li>{' '}
          </ul>
          <li>Set Binaries (Monthly)/ Exceed Tuner (Monthly)</li>{' '}
          <ul>
            <li>
              If you have any currency remaining, you may consider spending the
              rest on the second tier of Set Binaries, or buy an Exceed Tuner.
              Contrary to what the item description would suggest, Exceed Tuners
              can be used on any piece of gear. Exceed Tuners save Eternium that
              would otherwise go into the enhancement module farming.
            </li>{' '}
          </ul>
        </ol>
        <StaticImage
          src="../../../images/counterside/generic/shop_10.webp"
          alt="Shops and currencies"
        />
        <br />
        <StaticImage
          src="../../../images/counterside/generic/shop_11.webp"
          alt="Shops and currencies"
        />
        <br />
        <StaticImage
          src="../../../images/counterside/generic/shop_12.webp"
          alt="Shops and currencies"
        />
        <br />
        <ul>
          <li>Red outline - Priority</li>
          <li>Blue outline - Up to player discretion</li>
        </ul>
        <p>For more information on Dange Close, check this guide:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Danger Close"
            link="/counter-side/guides/danger-close"
            image={
              <StaticImage
                src="../../../images/counterside/categories/guide_dangerclose_overview.png"
                alt="Danger Close Overview"
              />
            }
          />
        </Row>
        <SectionHeader title="Substream Shops" />
        <p>
          Substreams are CounterSide’s equivalent to side stories. Substreams
          are released with Normal and Hard stages. Importantly, the Hard stages
          drop Unit Data for certain SSR/ SR characters, which can in sufficient
          quantities be redeemed for the full character. On top of Unit Data,
          you will also receive some currency for clearing the stage.
        </p>
        <p>
          Each Substream has its own dedicated currency that can be exchanged
          for various items from their respective shops. From time to time, the
          game will run events increasing currency drop rates for a specified
          Substream.
        </p>
        <p>
          The Substream shops are <strong>hideously inefficient</strong>, even
          during the drop rate up events. You would not go out of your way to
          farm currency to clear out the Substream shop.{' '}
          <strong>
            Substream shop offerings should be treated as a small bonus for when
            you farm Unit Data.
          </strong>
        </p>
        <p>
          The Substream shops shop can be accessed from:{' '}
          <strong>Shop &gt; Shop List &gt; Substream</strong>
        </p>
        <h5>What to Buy</h5>
        <ol>
          <li>Limited Substream-Exclusive Items</li>
          <ul>
            <li>
              Certain items can only be purchased from Substream shops. These
              are:
            </li>
            <ul>
              <li>Pure Possibilities</li>
              <li>Lobby Backgrounds</li>
            </ul>
          </ul>
          <li>Scarce Resources</li>
          <ul>
            <li>
              Set Binaries are purchasable from certain Substream shops. It is
              up to the player’s discretion how they choose to prioritize their
              Eternium.
            </li>
          </ul>
        </ol>
        <StaticImage
          src="../../../images/counterside/generic/shop_13.webp"
          alt="Shops and currencies"
        />
        <br />
        <ul>
          <li>Red outline - Priority</li>
          <li>Blue outline - Up to player discretion</li>
        </ul>
        <div className="fuse-ad-placeholder" data-fuse="22844297253"></div>
      </div>
    </DashboardLayout>
  );
};

export default CSGuidesShopPage;

export const Head: React.FC = () => (
  <Seo
    title="Shops and currencies | Counter Side | Prydwen Institute"
    description="This guide explains what's worth buying daily/weekly from all the
    shops."
  />
);
