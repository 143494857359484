import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import React from 'react';
import { Col } from 'react-bootstrap';

import './category-card.scss';

interface IProps {
  title: string;
  link: string;
  image: React.ReactNode;
  updated?: boolean;
  isNew?: boolean;
  isWIP?: boolean;
  disabled?: boolean;
  outsideLink?: boolean;
}

export const CategoryCard: React.FC<IProps> = ({
  title,
  link,
  image,
  updated,
  isNew,
  isWIP,
  disabled,
  outsideLink
}) => {
  return (
    <Col>
      {!disabled ? (
        <>
          {outsideLink ? (
            <>
              <a href={link} target="_blank" rel="noreferrer">
                <div className="category-card">
                  <div className="card-left">{image}</div>
                  <div className="card-right">
                    <p>{title}</p>
                    {updated && <span className="pill updated">Updated</span>}
                    {isNew && <span className="pill new">New</span>}
                    {isWIP && <span className="pill wip">WIP</span>}
                  </div>
                  <FontAwesomeIcon
                    icon={faLongArrowAltRight}
                    className="arrow"
                    width="18"
                  />
                </div>
              </a>
            </>
          ) : (
            <Link to={link}>
              <div className="category-card">
                <div className="card-left">{image}</div>
                <div className="card-right">
                  <p>{title}</p>
                  {updated && <span className="pill updated">Updated</span>}
                  {isNew && <span className="pill new">New</span>}
                  {isWIP && <span className="pill wip">WIP</span>}
                </div>
                <FontAwesomeIcon
                  icon={faLongArrowAltRight}
                  className="arrow"
                  width="18"
                />
              </div>
            </Link>
          )}
        </>
      ) : (
        <>
          <div className="category-card disabled">
            <div className="card-left">{image}</div>
            <div className="card-right">
              <p>{title}</p>
              {updated && <span className="pill updated">Updated</span>}
              {isNew && <span className="pill new">New</span>}
              {isWIP && <span className="pill wip">WIP</span>}
            </div>
            <FontAwesomeIcon
              icon={faLongArrowAltRight}
              className="arrow"
              width="18"
            />
          </div>
        </>
      )}
    </Col>
  );
};
